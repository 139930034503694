export default {
  'offer-01': {
    en: 'Get 50% off all subscription plans now - for a limited time only!',
    de: 'Sichere dir jetzt 50% Rabatt auf alle Abonnementpläne - nur für begrenzte Zeit!',
  },
  'offer-01-mobile': {
    en: 'Save up to 50%!',
    de: 'Bis zu 50% Rabatt!',
  },

  'pricing-sub-01': {
    en: 'Our subscription plans',
    de: 'Unsere Abonnementpläne',
  },
  'price-text-01': {
    en: 'We offer various solutions, for individuals and small teams, up to large organizations.',
    de: 'Wir bieten verschiedene Lösungen an, für Einzelpersonen und kleine Teams bis hin zu großen Organisationen.',
  },
  'price-text-02': {
    en: 'All new customers can test our product for free for 14 days.',
    de: 'Alle Neukunden können unser Produkt 14 Tage lang kostenlos testen.',
  },
  'pricing-toggle-01': {
    en: 'Monthly',
    de: 'Monatlich',
  },
  'pricing-toggle-02': {
    en: 'Annual',
    de: 'Jährlich',
  },
  'pricing-startup': {
    en: 'Best for small teams and individuals.',
    de: 'Perfekt für kleine Teams, Einzelpersonen oder Startups.',
  },
  'pricing-basic': {
    en: 'Best for larger teams or small organizations.',
    de: 'Perfekt für größere Teams oder kleine Organisationen.',
  },
  'pricing-premium': {
    en: 'Best for multiple teams or medium organizations.',
    de: 'Perfekt für mehrere Teams oder mittelgroße Organisationen.',
  },
  'pricing-corporate': {
    en: 'Best for scaling and larger organizations.',
    de: 'Perfekt für skalierende und größere Organisationen.',
  },
  'pricing-month': {
    en: 'month',
    de: 'Monat',
  },
  'pricing-reports': {
    en: 'Up to %r% Report',
    de: 'Bis zu %r% Reports',
  },
  'pricing-button': {
    en: 'Get started',
    de: 'Starte jetzt',
  },
  'pricing-components': {
    en: 'Diverse Chart Types',
    de: 'Diverse Chart Typen',
  },
  'pricing-table': {
    en: 'Standard & Pivoted Tables',
    de: 'Standard & Pivot-Tabellen',
  },
  'pricing-export': {
    en: 'Export all reports',
    de: 'Exportiere alle Reports',
  },
}
