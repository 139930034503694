export default {
  introduction: {
    en: 'Sharedex enables you to create powerful reports with simple data structures. To create a report, visit https://app.sharedex.co/add-report. Reports can have a name, an authentication key (explained in the "Authentication" section), and role-based access control. Additionally, one report at a time can be configured as your dashboard report. In the next section, we explain the structure of report components.',
    de: 'Sharedex ermöglicht es dir, leistungsstarke Berichte mit einfachen Datenstrukturen zu erstellen. Um einen Bericht zu erstellen, besuche https://app.sharedex.co/add-report. Berichte können einen Namen, einen Authentifizierungsschlüssel (erläutert im Abschnitt „Authentifizierung“) und eine rollenbasierte Zugriffskontrolle haben. Zusätzlich kann jeweils nur ein Bericht als dein Dashboard-Bericht konfiguriert werden. Im nächsten Abschnitt erklären wir die Struktur der Berichtskomponenten.',
  },

  'simple-table': {
    en: 'To create a simple table, here is a quick example using a users table:',
    de: 'Um eine einfache Tabelle zu erstellen, hier ein schnelles Beispiel mit einer Benutzertabelle:',
  },

  'simple-table-img': {
    en: 'This results in the following table:',
    de: 'Dies führt zur folgenden Tabelle:',
  },

  'pivot-table': {
    en: 'To create a pivot table, here is an example using MySQL. By adding a custom `pvt` field as shown below, you can structure the SQL results into a pivot-like format. This groups fields (e.g., `firstname`, `lastname`) into a nested JSON array for enhanced data organization and flexibility:',
    de: 'Um eine Pivot-Tabelle zu erstellen, hier ein Beispiel mit MySQL. Durch Hinzufügen eines benutzerdefinierten `pvt`-Felds, wie unten gezeigt, kannst du die SQL-Ergebnisse in ein Pivot-ähnliches Format umstrukturieren. Dadurch werden Felder (z. B. `firstname`, `lastname`) in einem verschachtelten JSON-Array gruppiert, was eine bessere Datenorganisation und Flexibilität ermöglicht:',
  },

  'pivot-table-img': {
    en: 'This results in the following table:',
    de: 'Dies führt zur folgenden Tabelle:',
  },

  'simple-graphs': {
    en: 'To create simple graphs, such as line, bar, column, or area charts, you can pass the query result directly:',
    de: 'Um einfache Diagramme wie Linien-, Balken-, Spalten- oder Flächendiagramme zu erstellen, kannst du das Abfrageergebnis direkt übergeben:',
  },

  'simple-graphs-img': {
    en: 'Here is an example result for the column chart type:',
    de: 'Hier ein Beispielergebnis für den Spaltendiagrammtyp:',
  },

  'advanced-graphs': {
    en: 'To create graphs grouped by a certain value (e.g., date, month), you can follow the approach below:',
    de: 'Um Diagramme zu erstellen, die nach einem bestimmten Wert gruppiert sind (z. B. Datum, Monat), kannst du die folgende Vorgehensweise verwenden:',
  },

  'advanced-graphs-img': {
    en: 'Here is an example result for the column chart type:',
    de: 'Hier ein Beispielergebnis für den Spaltendiagrammtyp:',
  },

  components: {
    en: 'Each report can have up to six components. In a component, you can specify the URL for the GET request, set its width, and define its type (e.g., table, chart). Additionally, you can include headers or custom parameters to send with the request. When the report loads, the component will send the request and display the data in the appropriate format.',
    de: 'Jeder Bericht kann bis zu sechs Komponenten haben. In einer Komponente kannst du die URL für die GET-Anfrage angeben, ihre Breite festlegen und den Typ definieren (z. B. Tabelle, Diagramm). Außerdem kannst du Header oder benutzerdefinierte Parameter hinzufügen, die mit der Anfrage gesendet werden sollen. Wenn der Bericht geladen wird, sendet die Komponente die Anfrage und zeigt die Daten im richtigen Format an.',
  },

  authentication: {
    en: 'You can specify an authentication key for a report. This key is securely stored in our database in an encrypted form. To verify the key before processing a report’s request, you can send a verification GET request to https://sharedex.co/verify?reportId=123&token=abc. The `reportId` and `token` will be included as query parameters in the component’s request. If valid, the request will respond with your decrypted token.',
    de: 'Du kannst einen Authentifizierungsschlüssel für einen Bericht angeben. Dieser Schlüssel wird sicher in unserer Datenbank in verschlüsselter Form gespeichert. Um den Schlüssel zu überprüfen, bevor die Anfrage eines Berichts verarbeitet wird, kannst du eine Verifizierungs-GE​​T-Anfrage an https://sharedex.co/verify?reportId=123&token=abc senden. Die `reportId` und der `token` werden als Abfrageparameter in der Anfrage der Komponente enthalten sein. Wenn gültig, antwortet die Anfrage mit deinem entschlüsselten Token.',
  },

  Introduction: {
    en: 'Introduction',
    de: 'Einführung',
  },

  Components: {
    en: 'Components',
    de: 'Komponenten',
  },

  Authentication: {
    en: 'Authentication',
    de: 'Authentifizierung',
  },

  'Simple Table': {
    en: 'Simple Table',
    de: 'Einfache Tabellen',
  },

  'Pivot Table': {
    en: 'Pivot Table',
    de: 'Pivot Tabellen',
  },

  'Simple Graphs': {
    en: 'Simple Graphs',
    de: 'Einfache Graphen',
  },

  'Advanced Graphs': {
    en: 'Advanced Graphs',
    de: 'Erweiterte Graphen',
  },
}
