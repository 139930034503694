export default {
  cookie_01: {
    en: 'We use cookies to make our site work better by storing limited information about your usage of the site.',
    de: 'Wir verwenden Cookies, um unsere Website besser zu machen, indem wir begrenzte Infos über deine Nutzung der Seite speichern.',
  },

  cookie_link: {
    en: 'Learn more',
    de: 'Mehr erfahren',
  },

  cookie_btn_reject: {
    en: 'Necessary Only',
    de: 'Nur notwendige',
  },

  cookie_btn_accept: {
    en: 'Accept all',
    de: 'Alle akzeptieren',
  },
}
