export default {
  'banner-h1': {
    en: 'Reporting made simple',
    de: 'Reporting leicht gemacht',
  },

  'banner-h2': {
    en: 'It doesn’t have to be complicated. Build powerful reports with simple SQL and clear, easy-to-understand data structures—all while staying in complete control of your data.',
    de: 'Es muss nicht kompliziert sein. Erstelle beeindruckende Berichte mit einfachem SQL und leicht verständlichen Datenstrukturen – und behalte dabei die volle Kontrolle über deine Daten.',
  },

  'banner-h3': {
    en: 'Trusted by over 1,500 individuals',
    de: 'Über 1.500 Nutzer setzen schon auf uns',
  },

  'banner-action-01': {
    en: 'Get started for free',
    de: 'Jetzt kostenlos starten',
  },

  'power-btn-01': {
    en: 'Start your journey',
    de: 'Starte deine Reise',
  },

  'demo-headline': {
    en: 'Our core features at a glance',
    de: 'Unsere Kernfunktionen auf einen Blick',
  },

  'demo-feature-01-headline': {
    en: 'Simple & Pivot Tables',
    de: 'Tabellen',
  },

  'demo-feature-01-text': {
    en: 'Easily create simple and pivot tables with all necessary operations. Filter data, hide columns, and download results effortlessly.',
    de: 'Erstelle ganz einfach Tabellen und Pivot-Tabellen mit allen notwendigen Funktionen. Filtere Daten, blende Spalten aus und lade Ergebnisse mühelos herunter.',
  },

  'demo-feature-02-headline': {
    en: 'Multiple Chart Types',
    de: 'Diagramme',
  },

  'demo-feature-02-text': {
    en: 'Choose from a wide variety of charts, such as column, pie, line, area-spline, and more. All charts can be customized individually.',
    de: 'Wähle aus einer Vielzahl an Diagrammen wie Säulen-, Kreis-, Linien-, Flächen-Diagrammen und mehr. Alle Diagramme sind individuell anpassbar.',
  },

  'demo-feature-03-headline': {
    en: 'Data Security',
    de: 'Datensicherheit',
  },

  'demo-feature-03-text': {
    en: 'We do not store your data. Requests are sent to your servers, and data is processed directly there, ensuring you stay in full control.',
    de: 'Wir speichern deine Daten nicht. Anfragen werden an deine Server gesendet, und die Daten werden direkt dort verarbeitet, sodass du die volle Kontrolle behältst.',
  },

  'demo-feature-04-headline': {
    en: 'Custom Requests',
    de: 'Anpassbare Anfragen',
  },

  'demo-feature-04-text': {
    en: 'All components (tables, charts) send GET requests to your servers, which can be customized with the required headers and query parameters.',
    de: 'Alle Komponenten (Tabellen, Diagramme) senden GET-Anfragen an deine Server, die mit den benötigten Headern und Query-Parametern angepasst werden können.',
  },

  'demo-feature-05-headline': {
    en: 'Authorization',
    de: 'Autorisierung',
  },

  'demo-feature-05-text': {
    en: 'All reports can include a verification key, which will be stored in encrypted form. A verification request can be sent to our endpoint to confirm that the stored key matches the report.',
    de: 'Alle Berichte können einen Schlüssel enthalten, der verschlüsselt gespeichert wird. Eine Verifikationsanfrage kann an unseren Endpoint gesendet werden, um den gespeicherte Schlüssel abzugleichen.',
  },

  'workflow-headline': {
    en: 'The Sharedex workflow',
    de: 'Der Sharedex-Workflow',
  },

  'workflow-headline-01': {
    en: 'Create Report',
    de: 'Report erstellen',
  },

  'workflow-body-01': {
    en: 'Reports require a name and can optionally be configured with a verification key and access permissions.',
    de: 'Berichte benötigen einen Namen und können optional mit einem Schlüssel und Zugriffberechtigungen konfiguriert werden.',
  },

  'workflow-headline-02': {
    en: 'Add Components',
    de: 'Komponenten hinzufügen',
  },

  'workflow-body-02': {
    en: 'Components include charts and tables. They require a URL to fetch the data and can be customized with headers and parameters.',
    de: 'Komponenten umfassen Diagramme und Tabellen. Sie benötigen eine URL, um die Daten abzurufen, und können mit Headern und Parametern angepasst werden.',
  },

  'workflow-headline-03': {
    en: 'Custom Filters',
    de: 'Benutzerdefinierte Filter',
  },

  'workflow-body-03': {
    en: 'Add filters to your report, such as date ranges or custom categories, for further customization. Filter values are included as parameters.',
    de: 'Füge deinem Bericht Filter wie Datumsbereiche oder benutzerdefinierte Kategorien hinzu, um ihn weiter anzupassen. Die Werte werden als Parameter gesendet.',
  },

  'workflow-headline-04': {
    en: 'Verify (Optional)',
    de: 'Verifikation (Optional)',
  },

  'workflow-body-04': {
    en: 'Each request includes a report ID and token. You can send them to our verification endpoint, which will return your stored key.',
    de: 'Jede Anfrage enthält eine report ID und einen Token. Du kannst diese an unseren Verifikations-Endpunkt senden, der dir deinen gespeicherten Schlüssel zurückgibt.',
  },

  'workflow-headline-05': {
    en: 'Customize & Export',
    de: 'Konfigurieren & Exportieren',
  },

  'workflow-body-05': {
    en: 'Export your data as an Excel or PDF file, or further customize your report components to fully meet your needs.',
    de: 'Exportiere deine Daten als Excel- oder PDF-Datei oder passe die Berichtskomponenten weiter an, um deinen Anforderungen vollständig zu entsprechen.',
  },

  'benefit-headline': {
    en: 'Why customers love Sharedex',
    de: 'Warum Kunden Sharedex lieben',
  },

  'benefit-headline-01': {
    en: 'Save Time',
    de: 'Zeitersparnis',
  },

  'benefit-body-01': {
    en: 'Setting up Sharedex is quick and effortless, getting you up and running in no time.',
    de: 'Die Einrichtung von Sharedex ist schnell und mühelos, sodass du in kürzester Zeit startklar bist.',
  },

  'benefit-headline-02': {
    en: 'Stay in Control',
    de: 'Behalte die Kontrolle',
  },

  'benefit-body-02': {
    en: 'Your data stays securely on your servers, giving you full control. We don’t store any of it.',
    de: 'Deine Daten bleiben sicher auf deinen Servern und unter deiner Kontrolle. Wir speichern nichts davon.',
  },

  'benefit-headline-03': {
    en: 'Customizable and User-Friendly',
    de: 'Anpassbar und benutzerfreundlich',
  },

  'benefit-body-03': {
    en: 'Sharedex is simple and intuitive out of the box, yet offers advanced customization options to suit your needs.',
    de: 'Sharedex ist von Anfang an einfach und intuitiv, bietet jedoch erweiterte Anpassungsoptionen, um deine Anforderungen zu erfüllen.',
  },

  'benefit-headline-04': {
    en: 'Support & Onboarding',
    de: 'Support & Einführung',
  },

  'benefit-body-04': {
    en: 'Every new user has the option to be guided through onboarding by a Sharedex team member and can always book a free support call.',
    de: 'Neue Nutzer können sich optional vom Sharedex-Team einführen lassen und jederzeit kostenlose Support-Termine buchen.',
  },
}
