export default {
  'nav-01': {
    en: 'Pricing',
    de: 'Preise',
  },
  'nav-02': {
    en: 'Login',
    de: 'Login',
  },
  'nav-03': {
    en: 'Sign up',
    de: 'Anmelden',
  },
  'nav-04': {
    en: 'German',
    de: 'Deutsch',
  },
  'nav-05': {
    en: 'English',
    de: 'Englisch',
  },
  'nav-08': {
    en: 'Language',
    de: 'Sprache',
  },
  'nav-09': {
    en: 'Contact us',
    de: 'Kontakt',
  },
  'nav-10': {
    en: 'Authentication',
    de: 'Authentifizieren',
  },
  'nav-11': {
    en: 'Company',
    de: 'Unternehmen',
  },
  'nav-12': {
    en: 'Legal',
    de: 'Impressum',
  },
  'nav-13': {
    en: 'Privacy',
    de: 'Datenschutz',
  },
  'nav-14': {
    en: 'Terms',
    de: 'AGB',
  },
  'nav-15': {
    en: 'Documentation',
    de: 'Dokumentation',
  },
  'nav-16': {
    en: 'Pricing',
    de: 'Preise',
  },
  'nav-19': {
    en: 'E-Mail',
    de: 'E-Mail',
  },
}
