<template>
  <div class="languages__container column">
    <div @click="toggleLanguage" class="link languages__toggle row">
      <div class="languages__toggle__icon row">
        <i class="fa-solid fa-caret-down" :class="{ expanded: showLanguages }"></i>
      </div>

      <i class="fa-solid fa-language"></i>
    </div>

    <div v-if="showLanguages" class="language__dropdown column box__shadow">
      <router-link
        @click="updateLanguage('de')"
        to=""
        class="language__dropdown__link row highlight"
      >
        {{ t('nav-04') }}
      </router-link>

      <router-link
        @click="updateLanguage('en')"
        to=""
        class="language__dropdown__link row highlight"
      >
        {{ t('nav-05') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { clickOutsideElement } from '@lib/helper.js'

export default {
  setup(_, { emit }) {
    const showLanguages = ref(false)

    const updateLanguage = (language) => {
      emit('updateLanguage', language)
      clearTimeout(languageTimeout)
      showLanguages.value = false
    }

    const toggleLanguage = () => {
      showLanguages.value = !showLanguages.value

      if (showLanguages.value)
        clickOutsideElement('.languages-container', () => {
          showLanguages.value = false
        })
    }

    let languageTimeout
    watchEffect(() => {
      if (showLanguages.value) {
        languageTimeout = setTimeout(() => {
          showLanguages.value = false
          clearTimeout(languageTimeout)
        }, 15000)
      }
    })

    return {
      showLanguages,
      toggleLanguage,
      updateLanguage,
    }
  },
}
</script>

<style lang="scss" scoped>
.languages__container {
  align-items: center;
  justify-content: center;
  position: relative;

  :hover {
    background-color: var(--highlight);
  }

  .languages__toggle {
    padding: 0 15px;
    border-radius: 10px;
    margin-left: 5px;
    cursor: pointer;
    font-size: 1.25rem;
    height: 45px;
    align-items: center;

    .languages__toggle__icon {
      margin-right: 7px;

      i.expanded {
        transform: rotate(180deg);
        transition: all 0.2s linear;
      }
    }
  }

  .language__dropdown {
    background-color: white;
    position: absolute;
    top: 47.5px;
    align-items: center;
    justify-content: center;

    .language__dropdown__link {
      width: calc(100% - 30px);
      margin-left: 0;
      padding: 5px 15px;
      font-size: 1.1rem;
    }
  }
}
</style>
