import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/:language(de|en)',
    name: 'Home',
    component: () => import('@modules/home/Home.vue'),
    meta: {
      title: 'Sharedex | Powerfull BI made simple',
    },
  },
  {
    path: '/:language(de|en)/pricing',
    name: 'Pricing',
    component: () => import('@modules/pricing/Pricing.vue'),
    meta: {
      title: 'Sharedex | Pricing',
    },
  },
  {
    path: '/:language(de|en)/documentation',
    name: 'Documentation',
    component: () => import('@modules/documentation/Documentation.vue'),
    meta: {
      title: 'Sharedex | Documentation',
    },
  },
  {
    path: '/:language(de|en)/legal',
    name: 'Legal',
    component: () => import('../views/Legal.vue'),
    meta: {
      title: 'Sharedex | Legal',
    },
  },
  {
    path: '/:language(de|en)/privacy',
    name: 'Data privacy',
    component: () => import('../views/Privacy.vue'),
    meta: {
      title: 'Sharedex | Privacy Policy',
    },
  },
  {
    path: '/:language(de|en)/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
    meta: {
      title: 'Sharedex | Terms & Conditions',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: store.state.language === 'de' ? '/de' : '/en',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }

    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  },
})

router.beforeEach(async function (to, from, next) {
  document.title = to.meta.title || 'Sharedex | Optimize your Workday!'

  if (to.params.language) store.commit('setLanguage', to.params.language)

  next()
})

export default router
