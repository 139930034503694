<template>
  <div class="navbar__container box__shadow row">
    <Logo @closeMenu="showMenu = false" />

    <Desktop @updateLanguage="updateLanguage($event)" />
    <ToggleBtn :showMenu="showMenu" @toggleMenu="showMenu = !showMenu" />
  </div>

  <Mobile
    :showMenu="showMenu"
    @closeMenu="showMenu = false"
    @updateLanguage="updateLanguage($event)"
  />
</template>

<script>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Logo from './components/Logo'
import ToggleBtn from './mobile/components/ToggleBtn'
import Desktop from './desktop'
import Mobile from './mobile'

export default {
  name: 'MainNavbar',
  components: { Logo, ToggleBtn, Desktop, Mobile },
  setup() {
    const showMenu = ref(false)
    const route = useRoute()
    const router = useRouter()

    const updateLanguage = (language) => {
      let url = route.fullPath
      let updatedUrl = url.slice(0, 1) + language + url.slice(3)
      router.push(updatedUrl)

      showMenu.value = false
    }

    return {
      showMenu,
      updateLanguage,
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar__container {
  position: fixed;
  top: 10px;
  background-color: white;
  width: calc(95% - 30px);
  padding: 0 15px;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  z-index: 15;
  margin-left: calc(2.5%);
  border-radius: 25px;
}
</style>
